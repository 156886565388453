import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

// Connects to data-controller="checkbox-label"
export default class extends Controller {
    static targets = ['input', 'label']
    static values = {
        checkedText: {String, default: i18n.t('yay')},
        uncheckedText: {String, default: i18n.t('nay')}
    }

    connect() {
        let inputTarget = this._findInputTarget();
        this._updateLabel(inputTarget.checked);
        inputTarget.addEventListener('change', (e) => this._updateLabel(e.target.checked))
    }

    _updateLabel(checked) {
        this._findLabelTarget().textContent = checked ? this.checkedTextValue : this.uncheckedTextValue;
    }

    _findInputTarget() {
        if (this.hasInputTarget) {
            return this.inputTarget;
        } else {
            let element = this.element.querySelector('input[type=checkbox]');
            if (element) {
                return element;
            } else {
                throw "Input target not found"
            }
        }
    }

    _findLabelTarget() {
        if (this.hasLabelTarget) {
            return this.labelTarget;
        } else {
            let element = this.element.querySelector('label');
            if (element) {
                return element;
            } else {
                throw "Label target not found"
            }
        }
    }
}
