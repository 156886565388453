import {Controller} from "@hotwired/stimulus";
import Pickr from '@simonwep/pickr';
import {debounce} from "throttle-debounce";
import i18n from "../i18n";

// Material palette
const DEFAULT_SWATCHES = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800'
]

// Connects to data-controller="color-picker-input"
export default class extends Controller {
    static targets = ['input', 'button', 'placeholder', 'pickerElement']
    static values = {
        placeholder: String,
        theme: {type: String, default: 'classic'}, // or 'monolith', or 'nano'
        swatches: {type: Array, default: DEFAULT_SWATCHES},
        showPreview: {type: Boolean, default: true},
        showHue: {type: Boolean, default: true},
        showOpacity: {type: Boolean, default: false},
        showClear: {type: Boolean, default: true},
        showSave: {type: Boolean, default: false}
    }

    connect() {
        this.pickr = Pickr.create({
            el: this._getPickerElement(),
            theme: this.themeValue,
            swatches: this.swatchesValue,
            useAsButton: true,
            default: this.getValue() || '#999999',

            components: {
                preview: this.showPreviewValue,
                opacity: this.showOpacityValue,
                hue: this.showHueValue,
                interaction: {
                    hex: false,
                    rgba: false,
                    hsla: false,
                    hsva: false,
                    cmyk: false,

                    input: this.inputTarget.type === 'hidden',
                    clear: this.showClearValue,
                    save: this.showSaveValue
                }
            },

            i18n: {
                'ui:dialog': i18n.t('js.color_input.dialog_title'),
                'btn:toggle': i18n.t('js.color_input.toggle_dialog'),
                'btn:swatch': i18n.t('js.color_input.swatch'),
                'btn:last-color': i18n.t('js.color_input.last_color'),
                'btn:save': i18n.t('apply'),
                'btn:cancel': i18n.t('cancel'),
                'btn:clear': i18n.t('clear'),
                // Strings used for aria-labels
                'aria:btn:save':  i18n.t('js.color_input.aria.save_and_close'),
                'aria:btn:clear':  i18n.t('js.color_input.aria.clear_and_close'),
                'aria:btn:cancel':  i18n.t('js.color_input.aria.close_without_saving'),
                'aria:input':  i18n.t('js.color_input.aria.input_field'),
                'aria:palette':  i18n.t('js.color_input.aria.palette'),
                'aria:hue':  i18n.t('js.color_input.aria.hue'),
                'aria:opacity':  i18n.t('js.color_input.aria.opacity')
            }
        });

        this.pickr.on('change', (color, source, instance) => {
            // User is selecting a color
            if (this.autocloseTimeout) {
                clearTimeout(this.autocloseTimeout);
                this.autocloseTimeout = null;
            }
            if (source === 'swatch') {
                // Hide after a small delay to give a bit more feedback
                this.autocloseTimeout = setTimeout(() => instance.hide(), 100);
            }
            this.setValue(color.toHEXA());
        }).on('changestop', (source, instance) => {
            // User has stopped selecting a color
            this.autocloseTimeout = setTimeout(() => instance.hide(), 3000);
        }).on('clear', () => {
            this.setValue(null);
        });

        this.inputTarget.addEventListener('input', debounce(500, () => {
            this.pickr.setColor(this.inputTarget.value);
        }));

        if (this.inputTarget.value) {
            this.buttonTarget.style.backgroundColor = this.inputTarget.value;
            if (this.hasPlaceholderTarget) {
                this.placeholderTarget.style.opacity = 0;
            }
        } else {
            if (this.hasPlaceholderTarget) {
                this.buttonTarget.style.backgroundColor = '#FAFAFA';
                this.placeholderTarget.style.opacity = 1;
            }
        }
    }

    disconnect() {
        super.disconnect();
        if (this.pickr) {
            this.pickr.destroy()
        }
    }

    _getPickerElement() {
        if (this.hasPickerElementTarget) {
            return this.pickerElementTarget;
        } else if (this.hasButtonTarget) {
            return this.buttonTarget;
        } else if (this.hasInputTarget && this.inputTarget.type !== 'hidden') {
            return this.inputTarget;
        } else {
            return this.element;
        }
    }

    setValue(color) {
        this._showValue(color);
        this.inputTarget.value = color
        this.inputTarget.dispatchEvent(new Event('change', {'bubbles': true}));
    }

    getValue() {
        if (this.inputTarget.value && this.inputTarget.value.trim().length > 0) {
            return this.inputTarget.value;
        } else {
            return null
        }
    }

    _showValue(color) {
        this.buttonTarget.style.backgroundColor = color ?? '#FAFAFA';
        this.inputTarget.value = color;
        if (this.hasPlaceholderTarget) {
            this.placeholderTarget.style.opacity = color ? 0 : 1;
        }
    }
}